import Vue from 'vue'
import Vuex from 'vuex'
import { getItem } from '../assets/js/storage.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getItem('token') || '',
    user: getItem('user') || '',
  },
  mutations: {
    setToken:(state, data) => {
      state.token = data;
    },
    setUser:(state, data) => {
      state.user = data;
    },
  },
  actions: {
    setToken({ commit }){
      commit('setToken', getItem('token'))
    },
    setUser({ commit }){
      commit('setUser', getItem('user'))
    }
  },
  modules: {
  }
})
