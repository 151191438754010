<template>
  <div class="item">
    <div class="f18 c333 pb20">{{title}}</div>
    <div class="myInput">
      <div :class="dir == 'col' ? 'base':'flex'">
        <div class="radio" v-for="(item, index) in list" :key="index" @click="tap(item)">
          <img src="@/assets/img/radio-check.png" alt="" v-if="item.value == radio">
          <img src="@/assets/img/radio.png" alt="" v-else>{{item.name}}
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:'',
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dir: {
      type: String,
      default: 'col'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
   return {
     radio: ''
   }
  },
  watch:{
    value(val){
      this.radio = val;
    }
  },
  methods:{
    tap(val){
      this.$emit('click', val.value);
    }
  }
}
</script>

<style scoped lang='scss'>
  .item{
    .myInput{
      position: relative;
      width: 440px;
      .base{
        flex-flow: column;
        display: flex;
        .radio{
          margin-bottom: 25px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .radio{
        margin-right: 80px;
        cursor: pointer;
        img{
          vertical-align: middle;
          margin-right: 12px;
        }
      }
    }
  }
</style>
