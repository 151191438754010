export default[
  {
    path: "/",
    name: "home",
    meta:{
      title: '首页',
      keepAlive: false,
      scroll: true
    },
    component: () => import("../../views/home.vue")
  },
  {
    path: "/problem",
    name: "problem",
    meta:{
      title: '胃健康风险评估',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/problem.vue")
  },
  {
    path: "/teach",
    name: "teach",
    meta:{
      title: '胃健康中医评估',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/teach.vue")
  },
  {
    path: "/result",
    name: "result",
    meta:{
      title: '胃健康风险评估',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/result.vue")
  },
  {
    path: "/choose",
    name: "choose",
    meta:{
      title: '胃镜推荐评估',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/choose.vue")
  },
  {
    path: "/teach-result",
    name: "teach-result",
    meta:{
      title: '胃健康中医评估',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/teach-result.vue")
  },
  {
    path: "/en",
    name: "home",
    meta:{
      title: 'Home',
      keepAlive: false,
      scroll: true
    },
    component: () => import("../../views/en/home.vue")
  },
  {
    path: "/en/problem",
    name: "problem",
    meta:{
      title: 'Stomach health risk assessment',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/en/problem.vue")
  },
  {
    path: "/en/teach",
    name: "teach",
    meta:{
      title: 'Stomach health assessment',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/en/teach.vue")
  },
  {
    path: "/en/result",
    name: "result",
    meta:{
      title: 'Stomach health risk assessment',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/en/result.vue")
  },
  {
    path: "/en/choose",
    name: "choose",
    meta:{
      title: 'Gastroscopy recommendation evaluation',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/en/choose.vue")
  },
  {
    path: "/en/teach-result",
    name: "teach-result",
    meta:{
      title: 'Stomach health assessment',
      keepAlive: false, // 是否进行缓存页面
      auth: true,   // 需要登录才能访问
      scroll: true, // 返回当前页时，是否保存滚动位置
    },
    component: () => import("../../views/en/teach-result.vue")
  }
]
