<template>
  <div class="car-item pt30 plr25" :style="'height:'+height+'px'">
    <div class="flex row-between">
      <div class="title f18 c333">{{title}}</div>
      <div><img src="@/assets/img/right.png" alt=""></div>
    </div>
    <div class="des f14 c666 pt10" v-html="des"></div>
  </div>
</template>

<script>
export default {
  name:'',
  props: {
    height: {
      type: [Number, String],
      default: 160
    },
    title: {
      type: String,
      default: ''
    },
    des: {
      type: String,
      default: ''
    }
  },
  data(){
   return {

   }
  }
}
</script>

<style scoped>
  .car-item{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;
  }
</style>
