<template>
  <div class="pic-card">
    <el-upload action="#" :class="{imgHide:imgHideUpload}" list-type="picture-card" :file-list="fileList" :limit="9" multiple :accept="accept" :http-request="Upload" :on-success="onSuccess" :on-exceed="exceed" :on-remove="handleRemove" :on-preview="handlePictureCardPreview" :before-upload="beforeUpload">
    <i class="el-icon-plus"></i>
  </el-upload>
  </div>
</template>

<script>
/**
 * @v-model 传参方式[{index: index, url: url}]
 * @function upload 返回{num:total, list:{index: index, url: url}},total为共上传了多少张图片，index当前已上传的图片下标
 * @function remove 返回obj, {index:index,url:url},index为图片下标
 * @example <upimage-more v-model="arrImg" @upload="uploadMore"></upimage-more>
 */
import OSS from 'ali-oss'
export default {
  name:'',
  props: {
      value: {
        type: Array,
        default() {
          return []
        }
      },
      accept: {
        type: String,
        default: ''
      },
    },
  data(){
    return {
      upUrl: [],
      obj: '',
      uploading: false,
      fileList: [],
      imgurl: [],
      num: 0
    }
  },
  watch:{
    value(newVal){
      this.imgurl = newVal;
      this.init();
    }
  },
  computed:{
    imgHideUpload: function(){
      return this.imgurl.length >= 9
    }
  },
  
  mounted(){
    if (this.value.length) {
      this.init();
    }
    this.$api.post('index/getsign').then(res => {
      this.obj = res;
      this.upUrl = res.host;
    })
  },
  methods:{
    init(){ // 父组件拿到数据后，使用ref访问来初始化
      this.fileList = this.imgurl;
      // this.imgurl = this.vallue;
    },
    beforeUpload(file){
      // return false;
    },
    Upload(options){
      var obj = this.getFileName(options.file);
      var res = this.obj;
      var client = new OSS({
        region: res.region_id,
        accessKeyId: res.Credentials.AccessKeyId,
        accessKeySecret: res.Credentials.AccessKeySecret,
        bucket: res.bucket,
        Expiration: res.Credentials.Expiration,
        stsToken: res.Credentials.SecurityToken
      })
      let file = options.file; // 拿到 file
      var that = this;
      client.multipartUpload("default/" + obj.timestamp + '/' + obj.file.name, file, {
        progress: function(p) {
          options.onProgress({percent: p * 100})
        }
      }).then(retult => {
        if (retult.res.statusCode === 200) {
          var url = res.host + '/' + retult.name;
          that.imgurl.push({url: url, index: that.num});
          var imgObj = {
            url: url,
            index: that.num,
            name: file.name
          }
          that.num++;
          options.onSuccess(imgObj)
        }else {
          options.onError("上传失败")
        }
      })
    },
    onSuccess(response, file, fileList){
      // console.log(fileList)
      this.fileList = fileList;
      // this.imgurl = fileList;
      this.$emit('upload', fileList);
    },
    exceed(){
      this.$message.error('最多上传9张图片')
    },
    handleRemove(file){
      // console.log(file)
      // var obj = {index: file.response[0].index, url: file.response[0].url};
      this.$emit('remove', file.response)
    },
    handlePictureCardPreview(file){
      this.$root.$emit('showPic', file.url);
    }
  }
}
</script>

<style scoped>
  .imgHide ::v-deep .el-upload--picture-card {
    display: none;
  }
</style>
