<template>
  <div>
    <div class="upBox" v-if="type == 'img'">
      <el-upload class="avatar-uploader" action="#" list-type="picture-card" :show-file-list="false" :http-request="Upload" :accept="accept">
        <span class="el-upload-list__item-actions" v-if="imgurl">
          <span class="el-upload-list__item-preview"> <i class="el-icon-zoom-in"></i> </span>
          <span class="el-upload-list__item-delete"> <i class="el-icon-delete"></i> </span>
        </span>
        <i v-if="!imgurl&&!uploading" class="el-icon-plus"></i>
        <img :src='imgurl' v-if="imgurl" width="148" class="pics" />
        <el-progress v-if="uploading" :percentage="percent" type="circle" :width='148'></el-progress>
      </el-upload>
    </div>
    <el-upload action="#" :show-file-list="false" :accept="accept" :http-request="Upload" v-if="type == 'file'">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
/**
 * @v-model 传参方式url
 * @function upload 返回url
 * @function remove 单纯只是一个回调
 * @example <upimage v-model="params.name" @upload="uploadImg" @remove="removeImg"></upimage>
 */
  import OSS from 'ali-oss'
  import $ from 'jquery'
  export default {
    name: '',
    props: {
      value: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      accept: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        upUrl: '',
        obj: '',
        imgurl: '',
        uploading: false,
        percent: 0,
        bHide: true
      }
    },
    watch:{
      value(newVal){
        this.imgurl = newVal;
      }
    },
    computed: {
      token(){
        return this.$store.state.token;
      }
    },
    mounted() {
      const h = this.$children[0].$children[0].handleClick
      this.$children[0].$children[0].handleClick = (event) => {
        if($(event.target).is('i.el-icon-zoom-in')){
          event.stopPropagation()
          this.$root.$emit('showPic', this.imgurl)
          return
        }
        if ($(event.target).is('i.el-icon-delete')) {
          event.stopPropagation()
          this.imgurl = ''
          this.syncToParent()
          return
        }
        h()
      }
      if (this.value) {
        this.imgurl = this.value;
      }
      console.log(this.token)
      var params = {
        token: this.token
      }
      this.$api.post('index/getsign', params).then(res => {
        this.obj = res.data;
        this.upUrl = res.data.host;
      })
    },
    methods: {
      syncToParent(){
        this.$emit('remove')
      },
      async Upload(options) {
        this.uploading = true;
        this.bHide = false;
        var obj = this.getFileName(options.file);
        var res = this.obj;
        var client = new OSS({
          region: res.region_id,
          accessKeyId: res.Credentials.AccessKeyId,
          accessKeySecret: res.Credentials.AccessKeySecret,
          bucket: res.bucket,
          Expiration: res.Credentials.Expiration,
          stsToken: res.Credentials.SecurityToken
        })
        let file = options.file; // 拿到 file
        var that = this;
        client.multipartUpload("default/" + obj.timestamp + '/' + obj.file.name, file, {
          progress: function(p) {
            that.percent = p * 100;
          }
        }).then(retult => {
          if (retult.res.statusCode === 200) {
            var url = res.host + '/' + retult.name;
            if(that.type == 'img'){
              that.imgurl = url;
              that.$emit('upload', url);
            }
            if(that.type == 'file'){
              var myobj = {
                url: url,
                name: file.name
              }
              that.$emit('upload', myobj);
            }
          }else {
            options.onError("上传失败")
          }
          that.uploading = false;
        })
      }
    }
  }
</script>

<style scoped>
  .upBox {
    width: 148px;
    height: 148px;
    position: relative;
  }

  .el-icon-zoom-in,
  .el-icon-delete {
    color: #FFFFFF;
  }
  .upBox ::v-deep .el-upload--picture-card{
    overflow: hidden;
    position: relative;
  }
  .pics{
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  .el-upload-list__item-actions span+span {
    margin-left: 15px;
  }

  .el-upload-list__item-delete {
    position: static;
    font-size: inherit;
    color: inherit;
  }

  .el-upload-list__item-actions:hover span {
    display: inline-block;
  }

  .el-upload-list__item-actions span {
    display: none;
    cursor: pointer;
  }

  .el-upload-list__item-actions:hover {
    opacity: 1;
  }

  .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s;
  }

  /* .avatar-uploader ::v-deep .el-upload--picture-card{
    width: 178px;
    height: 178px;
  } */
  .hide ::v-deep .el-upload--picture-card{
    display: none;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
