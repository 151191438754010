<template>
  <div>
    <div class="header flex row-between w1920">
      <div class="logo flex">
        <img src="@/assets/img/logo.png" alt="" style="cursor: pointer;" @click="$router.push('/')">
        <div class="English f12 ml10" @click="goHome">
          <img src="@/assets/img/tap.png" alt="">English
        </div>
      </div>
      <div class="user flex f18" v-if="token">
        <div class="avatar"><img :src="user.avatar" alt=""></div>
        <div class="name">{{user.username}}</div>
        <div class="out" @click="loginOut">退出</div>
      </div>
      <div class="login f18" v-else @click="getLogin">登录</div>
    </div>
    <div class="covers"></div>
  </div>
</template>

<script>
import { setItem } from "@/assets/js/storage.js"
export default {
  name: 'myHeader',
  data(){
    return {
      time: null
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    },
    user(){
      return this.$store.state.user;
    }
  },
  mounted(){
    setItem('lang', 'ch');
    // console.log(this.token)
  },
  methods: {
    goHome(){
      setItem('lang', 'en');
      this.$router.push('/en')
    },
    async getLogin(){
      this.$router.push('/login');
    },
    loginOut(){
      localStorage.clear();
      this.getLogin();
    }
  }
}
</script>

<style scoped lang="scss">
  .covers{
    width: 100%;
    height: 80px;
    
  }
  .header{
    background-color: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(186, 192, 206, 0.5);
    height: 80px;
    width: 100%;
    padding: 0 60px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    .user{
      .out{
        color: #999999;
        font-weight: 400;
        border-bottom: 1px solid #999999;
        padding-bottom: 2px;
        cursor: pointer;
      }
      .name{
        color: #333333;
        font-weight: 500;
        padding: 0 8px;
      }
      .avatar{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .login{
      width: 120px;
      height: 48px;
      line-height: 48px;
      border-radius: 48px;
      text-align: center;
      background-color: #857BFE;
      color: #FFFFFF;
      cursor: pointer;
    }
    .logo{
      .English{
        width: 70px;
        height: 20px;
        line-height: 20px;
        border-radius: 20px;
        border: 1px solid #666666;
        text-align: center;
        color: #666666;
        cursor: pointer;
        img{
          margin-right: 4px;
        }
      }
    }
  }
</style>
