<template>
  <div class="flex hd f32 c333">
    <img src="@/assets/img/img4.png">{{title}}
  </div>
</template>

<script>
export default {
  name:'',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data(){
   return {

   }
  }
}
</script>

<style scoped lang="scss">
  .hd{
    width: 100%;
    height: 115px;
    border-bottom: 1px solid #EBEBEB;
    img{
      margin-right: 10px;
    }
  }
</style>
