<template>
  <div class="bodyBg-image">
    <img src="@/assets/img/img3.png" class="img3">
    <my-header></my-header>
    <div class="box">
      <img src="@/assets/img/result.png" class="result">
      <div class="top f36 cfff">{{title}}</div>
      <div class="lany">
        <div class="f32 c333 pdm" v-html="content"></div>
        <div class="f24 c666 pt30 pdm">{{dist}}</div>
      </div>

      <div class="ft f14 c999 pdm">{{fot}}</div>
    </div>
    <div class="pr pt40 pb30" v-if="imgShow">
      <div class="w800 layer plr25 pb30">
        <div class="f18 c333 pt30 pb20">Upload a picture of gastroscopy results:</div>
        <upimage v-model="url" type="img" accept=".jpg,.png,.git,.pdf" @upload="uploadImg" @remove="removeImg"></upimage>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    dist: {
      type: String,
      default: ''
    },
    fot: {
      type: String,
      default: 'Refer to "Expert Consensus Opinions on Early Gastric Cancer Screening and Endoscopy in China (Changsha, April 2014)"'
    },
    id: {
      type: [Number, String],
      default: ''
    },
    imgShow: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      url: ''
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    }
  },
  methods:{
    async getUpLoad(){
      var params = {
        token: this.token,
        url: this.url,
        id: this.id
      }
      var res = await this.$api.post('/index/uploadfile', params);
      if (res.error_code == 0) {
        this.$message.success('Uploaded successfully');
        // this.$router.push('/')
      }else if (res.error_code == 1) {
        this.$message.error(res.msg);
      }
    },
    uploadImg(url){
      this.url = url;
      this.getUpLoad();
    },
    removeImg(value){
      this.url = '';
    },
  }
}
</script>

<style scoped lang="scss">
  .pr{
    position: relative;
    z-index: 2;
    .layer{
      background-color: #FFFFFF;
      border-radius: 20px;
      position: relative;
    }
  }
  .w800{
    width: 800px;
    margin: 0 auto;
  }
  .box{
    width: 800px;
    height: 660px;
    margin: 0 auto;
    margin-top: 90px;
    position: relative;
    z-index: 2;
    .pdm{
      padding: 0 70px;
    }
    .lany{
      position: absolute;
      top: 320px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }
    .ft{
      position: absolute;
      bottom: 85px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }
    .top{
      line-height: 1;
      position: absolute;
      left: 50px;
      top: 45px;
    }
    .result{
      width: 100%;
      height: 100%;
    }
  }
  .bodyBg-image{
    min-height: 100vh;
  }
  .img3{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
</style>
