const foowwLocalStorage = {
  set: function(key, value, ttl_ms) {
    var data = {
      value: value
    };
    if(ttl_ms) data.expirse = new Date(ttl_ms).getTime()
    
    localStorage.setItem(key, JSON.stringify(data));
  },
  get: function(key) {
    var data = JSON.parse(localStorage.getItem(key));
    if (data !== null) {
      if (data.expirse != null && data.expirse < new Date().getTime()) {
        localStorage.removeItem(key);
      } else {
        return data.value;
      }
    }
    return null;
  }
}

export function getItem(key){
  var str = foowwLocalStorage.get(key);
  return str;
}
export function setItem(key, value, date){
  foowwLocalStorage.set(key, value, date);
}