<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="router-view keep"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="router-view"></router-view>

    <el-dialog :visible.sync="picDialog.visible">
      <img width="100%" :src="picDialog.imgurl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default{
  data(){
    return{
      picDialog: {
        visible: false,
        imgurl: ''
      },
    }
  },
  created() {
    var that = this;
    this.$root.$on('showPic',function(value){
      that.showPicDialog(value);
    })
  },
  methods: {
    showPicDialog(url) {
      this.picDialog.visible = true;
      this.picDialog.imgurl = url;
    },
  }
}
</script>


<style lang="scss">
  #app {
    font-family: "Microsoft YaHei","微软雅黑","Microsoft JhengHei","华文细黑","STHeiti,MingLiu";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    // height: 100%;
  }
  .bodyBg{
    background-color: #F8F9FA;
    height: 100%;
    position: relative;
  }
  .bodyBg-image{
    background-color: #f2f2f3;
    height: 100%;
    position: relative;
  }
  .w1920{
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
  }
  .w1200{
    width: 1200px;
    margin: 0 auto;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
</style>
