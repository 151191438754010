import Vue from 'vue'

import ElementUI from 'element-ui';
import './assets/theme/index.css';

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/common.scss'
import '@/components'
import * as api from '@/api'
import global from '@/utils/index'
// console.log(global)
Vue.prototype.$api = api;
for (var k in global) {
  Vue.prototype[k] = global[k];
}

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
