import axios from 'axios';
import qs from 'qs';
// var url = process.env.NODE_ENV == 'development' ? '' : process.env.BASE_URL;
var url = process.env.NODE_ENV == 'development' ? '' : 'https://api.bianque.vip/';
const http = axios.create({
  baseURL: url,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
    'Client-Type': 'MALL_MOBILE_SECRET_MINI'
  }
})

// 请求拦截器
http.interceptors.request.use(config => {
  // config.header.Token = 'xxxxxx';
  config.data = qs.stringify(config.data, { arrayFormat: 'repeat' })
  return config;
}, err => {
  return err;
})

// 响应拦截器
http.interceptors.response.use(res => {
  if(res.status === 200){
    return res.data;
  }
}, err => {
  response => {
    return response.data
  },

  /**
    * 全局错误统一处理
    */
  error => {
    switch (error.response.status) {
      case 422:
        let data = error.response.data.errors
        let content = ''

        Object.keys(data).map(function (key) {
          let value = data[key]
          content = value[0]
        })

        Message.error(content)
        break
      case 403:
        Message.error(error.response.data.message || '您没有此操作权限！')
        break
      case 401:
        if (window.location.pathname !== '/login') {
           //跳转登录页
            window.location.href = '/login'
        }
        break
      case 429:
        Message.error(error.response.data.message || '请求过于频繁！')
        break
      case 500:
      case 501:
      case 503:
      default:
        Message.error('服务器错误!')
    }

    return Promise.reject(error.response)
  }
})

export default http