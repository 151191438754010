import timeFormat from './timeFormat.js';
import md5Libs from './md5.js';
export default {
  timeFormat,
  getFileName(file){
    var dateTime = Date.parse(new Date());
    var timestamp = timeFormat(dateTime, 'yyyy-mm-dd');
    var ran6 = this.MathRand(6);
    var fileName = md5Libs.md5(dateTime.toString() + ran6.toString());
    var jp = file.name.substring(file.name.lastIndexOf('.') + 1);
    const copyFile = new File([file], `${fileName}.${jp}`)
    // var aliyunFileKey = 'default/' + timestamp + '/' + copyFile;
    var obj = {
      file: copyFile,
      timestamp: timestamp
    }
    return obj;
  },
  MathRand(number){
    var Num="";
    for(var i = 0; i < number; i++){
      Num += Math.floor(Math.random()*10);
    }
    return Num;
  },
  toTop(selector){
    let element = selector && document.querySelector(selector) || window;
    element.scrollTo(0, 0);
  }
}
